import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Dash } from './styles'
import Card from '../../UI/card'
import UserWrapper from '../../hoc/userWrapper'
import { useDispatch, useSelector } from 'react-redux'
import { fetchDashboard } from '../../../store/actions/dashboard'
import { getLimnimeters } from '../../../store/actions/limnimeters'
import { GhostLine } from '../../UI/ghostLoader'
import moment from 'moment'

const AdminDashboard = props => {
  const dispatch = useDispatch()
  const history = useHistory()
  const dashData = useSelector(({ dashboard }) => dashboard)
  const user = useSelector(({ user }) => user)
  const session = useSelector(({ login }) => login)
  const listLimnimeter = useSelector(({ limnimeters }) => limnimeters)
  // const messageAssignment = "CONSIGNA (JVRM)"
  // const incidents = dashData?.readings_list?.incidents || [];

  useEffect(() => {
    if (user) {
      dispatch(fetchDashboard(null, user))
    }
  }, [dispatch, session, user])

  useEffect(() => {
    dispatch(getLimnimeters(user))
  }, [dispatch, user])

  return (
    <UserWrapper>
      <Dash>
        {dashData &&
          session &&
          session.hasOwnProperty('association_user') &&
          dashData.hasOwnProperty('requests') &&
          dashData.hasOwnProperty('next_visit') &&
          dashData.hasOwnProperty('acm') &&
          dashData.hasOwnProperty('readings_list') ? (
          <>
            {/* 
             ACTUAL READING DATA
            <Card
              className='stat-card limnimeters'
              title='Medición de Limnímetro'
              onClick={() => {
                console.log(dashData.readings_list)
              }}
            >
              <div className='title-limnimeters'>
                <h4>Limnímetro {dashData.readings_list.limnimeter.channels.name}</h4>
              </div>

              <section>

                <span>
                  <strong>{messageAssignment}</strong>
                  <div className='assignment'>
                    <h1>{dashData.readings_list.consigna}%</h1>
                  </div>
                </span>

                <span className='assignment-real'>
                  <span>
                    <strong>Litros/Segundo</strong>
                    <h1>{dashData.readings_list.caudal}</h1>
                  </span>
                  <span>
                    <strong>Real ACM</strong>
                    <h1>{dashData.readings_list.porcentajeReal}%</h1>
                  </span>
                </span>

                <div className='alert'>
                  <div className='alert-header'>
                    <div>

                    </div>
                    {
                      dashData.readings_list.porcentajeReal > dashData.readings_list.consigna ?
                        <strong>Canal en Perfecto Estado</strong> :
                        dashData.readings_list.consigna === dashData.readings_list.porcentajeReal ?
                          <strong>Canal en Correcto Estado</strong> :
                          <strong>Canal en Mal Estado</strong>

                    }
                  </div>
                  <div className='alert-content'>
                    {
                      dashData.readings_list.porcentajeReal > dashData.readings_list.consigna ?
                        <h5>El agua pasante está por sobre la consigna de la JVRM.</h5> :
                        dashData.readings_list.porcentajeReal === dashData.readings_list.consigna ?
                          <h5>El agua pasante está de acuerdo a la consigna de la JVRM.</h5> :
                          <h5>Hemos detectado una diferencia en la cantidad de agua pasante con la consigna de la JVRM.</h5>


                    }

                    <div>
                      {(dashData.readings_list.incidents != null) ? (
                        <div>
                          <h5>*Presencia de</h5>
                          {dashData.readings_list.incidents.map((incident) => (
                            <h5 key={incident.id}>{incident.name}</h5>
                          ))}
                        </div>
                      ) :
                        <h5>No hay Incidencias</h5>
                      }
                    </div>
                    {
                      dashData.readings_list.porcentajeReal < dashData.readings_list.consigna ?
                        <h5>Estamos trabajando para solucionar el problema lo antes posible.</h5> : <></>
                    }
                  </div>
                </div>
                <div className='footer'>
                  <footer>Fecha y Hora de Actualización {dashData.readings_list.dateCreation} hrs</footer>
                </div>
              </section>

            </Card> */}


            <Card
              className='stat-card readings'
              title="Lectura de Limnímetros"
            onClick={() => {
              history.push('/limnimetros')
            }}
            >
              <section>
                <div className='channels'>
                  <strong>Canales</strong>
                  {
                    listLimnimeter.map((limnimeter,index) => (
                      <h2 key={index}> * {limnimeter.name}</h2>
                    )
                    )}
                </div>
                <div className='pendings'>
                  <strong>Pendientes</strong>
                  <div className='number'>
                    <h1>{listLimnimeter?.length}</h1>
                  </div>
                </div>
              </section>
            </Card>

            <Card
              className='stat-card visits'
              title='Solicitudes de Atención'
              onClick={() => {
                history.push('/solicitudes')
              }}
            >
              <section>
                <span>
                  <strong>Pendientes</strong>
                  <h1>{dashData.requests.closed}</h1>
                </span>
                <span>
                  <strong>Cerradas</strong>
                  <h1>{dashData.requests.closed}</h1>
                </span>
              </section>
            </Card>

            <Card
              className='stat-card'
              title='Regantes'
              onClick={() => {
                history.push('/regantes')
              }}
            >
              <section>
                <strong>Lista de rol de regantes:</strong>
                <h1>
                  {(dashData && dashData.total_irrigators) || 'No disponible'}
                </h1>
              </section>
              <footer>{`Actualizado el ${dashData.last_update_irrigators}`}</footer>
            </Card>

            <Card
              className='stat-card'
              title='Reportes'
              onClick={() => {
                history.push('/visitas')
              }}
            >
              <section>
                <strong>Próxima visita programada:</strong>
                <h1>
                  {dashData.next_visit &&
                    dashData.next_visit.hasOwnProperty('visitreport_data')
                    ? moment(dashData.next_visit.visitreport_data.date).format(
                      'DD/MM/YYYY HH:mm'
                    )
                    : 'Sin definir'}
                </h1>
              </section>
            </Card>

            <Card
              className='stat-card'
              title='Información General'
              subTitle={dashData.acm.acm_regulation.origin}
              notifications={dashData.notifications.length}
              onClick={() => {
                history.push('/canales')
              }}
            >
              <section>
                <strong>Caudal Diario:</strong>
                <h1>{dashData.acm.acm_regulation.value}</h1>
              </section>
              <footer>{`Actualizado el: ${dashData.acm.acm_regulation.updated}`}</footer>
            </Card>
          </>
        ) : (
          <Card className='stat-card'>
            <GhostLine />
            <GhostLine width='60%' />
            <GhostLine width='40%' />
          </Card>
        )}
      </Dash>
    </UserWrapper>
  )
}

export default AdminDashboard
